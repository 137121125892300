/**
 *
 * @param session string com o nome da localStorage
 */
export function setSessions(session) {
    if (session === 'Mentoring') {
        /**
         * @param url string com a url destino no backend
         * @param params objeto com os campos do filtro, devem ter a mesma chave dos dados vindos do backend
         */
        const users = {
            url: 'mentorings',
            params: {
                page: 1,
                order_by: '',
                order_type: 'ASC',
                orderBy: 'asc',
                limit: 25,
                title: '',
                type_id: '',
            }
        }
        localStorage.setItem('Mentoring', JSON.stringify(users));
    } else if (session === 'Form') {
        const users = {
            url: 'forms',
            params: {
                page: 1,
                order_by: '',
                order_type: 'ASC',
                orderBy: 'asc',
                limit: 25,
                title: '',
            }
        }
        localStorage.setItem('Form', JSON.stringify(users));
    } else if (session === 'Notifications') {
        const notifications = {
            url: 'notifications',
            params: {
                page: 1,
                order_by: '',
                order_type: 'ASC',
                orderBy: 'asc',
                limit: 25,
                mentee: '',
                type: '',
            }
        }
        localStorage.setItem('Notifications', JSON.stringify(notifications));
    } else if (session === 'ApplicationFormDashboard') {
        const notifications = {
            url: 'form-applications',
            params: {
                page: 1,
                order_by: 'id',
                order_type: 'ASC',
                orderBy: 'asc',
                limit: 25,
                with: ['mentoring', 'task'],
            }
        }
        localStorage.setItem('ApplicationFormDashboard', JSON.stringify(notifications));
    }  else if (session === 'FormDashboard') {
        const notifications = {
            url: 'tasks/list-by-mentor',
            params: {
                page: 1,
                order_by: 'id',
                order_type: 'ASC',
                orderBy: 'asc',
                limit: 25,
                type: '3',
            }
        }
        localStorage.setItem('FormDashboard', JSON.stringify(notifications));
    } else if (session === 'ActivityDashboard') {
        const notifications = {
            url: 'tasks/list-by-mentor',
            params: {
                page: 1,
                order_by: 'id',
                order_type: 'ASC',
                orderBy: 'asc',
                limit: 25,
                type: '4',
            }
        }
        localStorage.setItem('ActivityDashboard', JSON.stringify(notifications));
    } else if (session === 'ScheduleDashboard') {
        const notifications = {
            url: 'tasks/list-by-mentor',
            params: {
                page: 1,
                order_by: 'id',
                order_type: 'ASC',
                orderBy: 'asc',
                limit: 25,
                type: '5',
            }
        }
        localStorage.setItem('ScheduleDashboard', JSON.stringify(notifications));
    } else if (session === 'Coupon') {
        const coupon = {
            url: 'coupons',
            params: {
                page: 1,
                order_by: 'id',
                order_type: 'ASC',
                orderBy: 'asc',
                limit: 25,
                code: '',
                mentoring_id: '',
                valid_from: '',
                valid_until: '',
            }
        }
        localStorage.setItem('Coupon', JSON.stringify(coupon));
    }  else if (session === 'Leads') {
        const coupon = {
            url: 'menteeMentorings',
            params: {
                page: 1,
                order_by: 'created_at',
                order_type: 'ASC',
                orderBy: 'asc',
                limit: 25,
                mentoring_id: '',
                name: '',
            }
        }
        localStorage.setItem('Leads', JSON.stringify(coupon));
    } else if (session === 'Sales') {
        const coupon = {
            url: 'menteeMentorings',
            params: {
                page: 1,
                order_by: 'created_at',
                order_type: 'ASC',
                orderBy: 'asc',
                limit: 25,
                mentoring_id: '',
                name: '',
            }
        }
        localStorage.setItem('Sales', JSON.stringify(coupon));
    } else if (session === 'Withdrawals') {
        const coupon = {
            url: 'withdrawals',
            params: {
                page: 1,
                order_by: 'created_at',
                order_type: 'ASC',
                orderBy: 'asc',
                limit: 25,
                start: '',
                end: '',
            }
        }
        localStorage.setItem('Withdrawals', JSON.stringify(coupon));
    }
}
