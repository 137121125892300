<template>
    <Layout>
        <div class="row">

            <div class="col-12">
                <h3>Financeiro</h3>
            </div>

            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <FilterBase session="Withdrawals" :filter="filter"/>
                    </div>
                </div>
            </div>

            <div class="col-md-6 v-step-0">
                <div class="card border-1 border-left-3 border-left-primary text-center">

                    <div class="card-body">
                        <h4 class="mb-0" v-if="released">
                            {{ maskMoneyTable(released) }}
                        </h4>
                        <b-skeleton v-else type="button" class="mx-auto"></b-skeleton>
                        <div>Saldo disponível</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card border-1 border-left-3 border-left-warning text-center">

                    <div class="card-body">
                        <h4 class="mb-0" v-if="on_hold || on_hold === 0">
                            {{ maskMoneyTable(on_hold) }}
                        </h4>
                        <b-skeleton type="button" v-else class="mx-auto"></b-skeleton>
                        <div>Saldo pendente</div>
                    </div>

                </div>
            </div>


            <div class="col-12">
                <div class="card">
                    <div class="card-body">

                        <div class="col-12 text-end">
                            <button type="button" class="btn btn-outline-primary" @click="showModal=true">
                                Efetuar Saque
                            </button>
                        </div>

                        <TableList session="Withdrawals" url="financeiro/" :table="table" :keys="keys">

                            <template #created="{ value }">
                                {{ convertDateText(value.created_at) }}
                            </template>

                            <template #value="{ value }">
                                {{ maskMoneyTable(value.value) }}
                            </template>

                            <template #status="{ value }">
                                <BBadge v-if="value.status === 1" variant="warning" class="fs-12">Processando</BBadge>
                                <BBadge v-else-if="value.status === 2" variant="success" class="fs-12">Sucesso</BBadge>
                                <BBadge v-else-if="value.status === 3" variant="danger" class="fs-12">Negado</BBadge>
                            </template>

                        </TableList>

                    </div>
                </div>
            </div>

        </div>

        <BModal v-model="showModal" title="Solicitar Saque" centered scrollable class="v-modal-custom" hide-footer
                @hidden="reset">


            <div class="row" id="modal">

                <div class="col-md-6 mb-3">
                    <label for="value" class="form-label">Valor <span class="text-danger">*</span></label>
                    <input placeholder="Valor do Saque" type="text" class="form-control" id="value"
                           v-model="formData.value" @keyup="formData.value=formatMoneyDecimal(formData.value, true)">
                </div>

                <div class="col-md-6 mb-3">
                    <label for="type" class="form-label">Tipo de saque <span class="text-danger">*</span></label>
                    <select id="type" class="form-control" v-model="formData.operationType">
                        <option value="">Selecionar</option>
                        <option value="PIX">Pix</option>
                        <option value="TED">TED</option>
                    </select>
                </div>

                <div class="col-md-6 mb-3" v-if="formData.operationType === 'PIX'">
                    <label for="pixAddressKeyType" class="form-label">Tipo da chave <span
                        class="text-danger">*</span></label>
                    <select id="pixAddressKeyType" class="form-control" v-model="formData.pixAddressKeyType">
                        <option value="">Selecionar</option>
                        <option value="CPF">CPF</option>
                        <option value="CNPJ">CNPJ</option>
                        <option value="EMAIL">E-mail</option>
                        <option value="PHONE">Telefone</option>
                    </select>
                </div>

                <div class="col-md-6 mb-3" v-if="formData.operationType === 'PIX'">
                    <label for="pixAddressKey" class="form-label">Chave Pix <span class="text-danger">*</span></label>
                    <input placeholder="Chave Pix" type="text" class="form-control" id="pixAddressKey"
                           v-model="formData.pixAddressKey">
                </div>

                <div class="col-md-6 mb-3" v-if="formData.operationType === 'PIX'">
                    <div class="d-flex justify-content-between">
                        <label for="date" class="form-label">Data </label>
                        <i v-b-tooltip.hover.right="'Para transferências agendadas, caso não informado o pagamento é instantâneo.'"
                           class="fa fa-info-circle ml-2 my-auto"></i>
                    </div>
                    <input placeholder="Data da transferência" type="text" class="form-control" id="date"
                           v-model="formData.scheduleDate">
                </div>

                <div class="col-md-6 mb-3" v-if="formData.operationType === 'TED'">
                    <label for="code" class="form-label">Banco <span class="text-danger">*</span></label>
                    <select id="code" class="form-control" v-model="formData.bankAccount.bank.code">
                        <option value="">Selecionar</option>
                        <option :value="bank.code" v-for="bank in banks" :key="`option-bank-${bank.id}`">
                            {{ bank.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-6 mb-3" v-if="formData.operationType === 'TED'">
                    <label for="pixAddressKeyType" class="form-label">Tipo da conta <span
                        class="text-danger">*</span></label>
                    <select id="pixAddressKeyType" class="form-control" v-model="formData.bankAccount.bankAccountType">
                        <option value="">Selecionar</option>
                        <option value="CONTA_CORRENTE">Conta Corrente</option>
                        <option value="CONTA_POUPANCA">Conta Poupança</option>
                    </select>
                </div>

                <div class="col-md-6 mb-3" v-if="formData.operationType === 'TED'">
                    <label for="ownerName" class="form-label">Títular <span class="text-danger">*</span></label>
                    <input placeholder="Nome do titular da conta" type="text" class="form-control" id="ownerName"
                           v-model="formData.bankAccount.ownerName">
                </div>

                <div class="col-md-6 mb-3" v-if="formData.operationType === 'TED'">
                    <label for="cpfCnpj" class="form-label">CPF/CNPJ <span class="text-danger">*</span></label>
                    <input placeholder="CPF ou CNPJ do titular" type="text" class="form-control" id="cpfCnpj"
                           v-model="formData.bankAccount.cpfCnpj" v-maska="['###.###.###-###', '##.###.###/####-##']">
                </div>

                <div class="col-md-6 mb-3" v-if="formData.operationType === 'TED'">
                    <label for="agency" class="form-label">Agência <span class="text-danger">*</span></label>
                    <input placeholder="Agência sem o dígito" type="text" class="form-control" id="agency"
                           v-model="formData.bankAccount.agency"
                           @keyup="formData.bankAccount.agency = formData.bankAccount.agency.substring(0, 5)">
                </div>

                <div class="col-md-6 mb-3" v-if="formData.operationType === 'TED'">
                    <label for="account" class="form-label">Conta <span class="text-danger">*</span></label>
                    <input placeholder="Conta sem o dígito" type="text" class="form-control" id="account"
                           v-model="formData.bankAccount.account">
                </div>

                <div class="col-md-6 mb-3" v-if="formData.operationType === 'TED'">
                    <label for="accountDigit" class="form-label">Dígito <span class="text-danger">*</span></label>
                    <input placeholder="Dígito da conta bancária" type="text" class="form-control" id="accountDigit"
                           v-model="formData.bankAccount.accountDigit"
                           @keyup="formData.bankAccount.accountDigit = formData.bankAccount.accountDigit.substring(0, 1)">
                </div>

                <div class="col-12 text-end border-top pt-3">
                    <button type="button" id="closeModalRegister" class="btn btn-outline-danger"
                            @click="showModal=false">
                        Cancelar
                    </button>
                    <button type="button" id="save" @click="save" class="btn btn-outline-success ml-2">Salvar</button>
                </div>

            </div>

        </BModal>

    </Layout>
</template>

<script>

import Layout from "@/components/layout/main.vue";
import TableList from "@/components/base/table-list.vue";
import {convertDateText, formatMoneyDecimal, maskMoneyTable, moneyToFloat} from "@/components/composables/masks";
import {setSessions} from "@/components/composables/setSessions";
import FilterBase from "@/components/base/filter-component.vue";
import {mapState} from "vuex";
import {endLoading, startLoading} from "@/components/composables/spinners";
import {ValidateForm} from "@/components/composables/cruds";
import http from '@/http'

export default {
    components: {
        FilterBase,
        Layout,
        TableList
    },
    data() {
        return {
            showModal: false,
            banks: [],
            formData: {
                value: '',
                operationType: 'TED',
                pixAddressKey: '',
                scheduleDate: '',
                pixAddressKeyType: '',
                bankAccount: {
                    bank: {
                        code: ''
                    },
                    ownerName: '',
                    cpfCnpj: '',
                    agency: '',
                    accountDigit: '',
                    bankAccountType: '',
                }
            },
            table: [
                {
                    column: 'Data',
                },
                {
                    column: 'Valor',
                },
                {
                    column: 'Status',
                },
            ],
            keys: ['created', 'value', 'status'],
            filter: {
                inputs: [
                    {
                        name: 'start',
                        label: 'Dê',
                        col: '3',
                        type: 'date'
                    },
                    {
                        name: 'end',
                        label: 'Até',
                        col: '3',
                        type: 'date'
                    },
                ],
            }
        }
    },

    methods: {
        formatMoneyDecimal,
        maskMoneyTable, convertDateText,

        reset() {
            this.formData = {
                value: '',
                operationType: 'PIX',
                pixAddressKey: '',
                pixAddressKeyType: '',
                scheduleDate: '',
                bankAccount: {
                    bank: {
                        code: ''
                    },
                    ownerName: '',
                    cpfCnpj: '',
                    agency: '',
                    account: '',
                    accountDigit: '',
                    bankAccountType: '',
                }
            }
        },

        save() {
            startLoading('modal', 'save');

            let required = {
                value: 'Valor',
                operationType: 'Tipo de saque',
            }

            if (this.formData.operationType === 'PIX') {
                required = {
                    ...required,
                    pixAddressKey: 'Chave Pix',
                    pixAddressKeyType: 'Tipo da Chave Pix',
                }

                if (!ValidateForm(this.$root, this.formData, required)) {
                    endLoading('modal', 'save');
                    return;
                }
            } else {
                if (!ValidateForm(this.$root, this.formData, required)) {
                    endLoading('modal', 'save');
                    return;
                }

                required = {
                    bankAccountType: 'Tipo da conta',
                    ownerName: 'Titular',
                    cpfCnpj: 'CPF/CNPJ',
                    agency: 'Agencia',
                    account: 'Conta',
                    accountDigit: 'Dígito',
                }

                if (!ValidateForm(this.$root, this.formData.bankAccount, required)) {
                    endLoading('modal', 'save');
                    return;
                }

                if (this.formData.bankAccount.bank.code === '') {
                    endLoading('modal', 'save');
                    this.$notifyError('Selecione um Banco!');
                    return;
                }
            }

            if (this.released < moneyToFloat(this.formData.value)) {
                this.$notifyError(`O valor máximo para saque é de ${maskMoneyTable(this.released)}`)
                endLoading('modal', 'save');
                return;
            }

            this.formData.value = moneyToFloat(this.formData.value);

            http.post('withdrawals', this.formData)
                .then(response => {
                    this.$notifySuccess(response.data);
                    this.showModal = false;
                })
                .catch(e => {
                    console.error(e);
                    this.$notifyError(e);
                    this.formData.value = formatMoneyDecimal(this.formData.value, true)
                })
                .finally(() => {
                    endLoading('modal', 'save');
                })
        },

        getBanks() {
            http.get('banks/?paginated=false')
                .then(response => {
                    this.banks = response.data.data;
                })
        }
    },

    mounted() {
        if (!localStorage.getItem('Withdrawals')) setSessions('Withdrawals');
        this.getBanks();
    },

    computed: {
        ...mapState({
            on_hold: state => state.api.listAll.values?.on_hold,
            released: state => state.api.listAll.values?.released,
        })
    },
}

</script>
